@import '../../scss/variables';

.section {
    padding-top: 65px;
    padding-bottom: 65px;
    @media all and (min-width: $tablet) {
        padding-top: 95px;
    }
    &__image {
        width: 270px;
        height: 270px;
        border-radius: 50%;
        padding: 8px;
        border: 2px solid $grassGreen;
        @media all and (min-width: $tablet) {
            width: 350px;
            height: 350px;
        }
    }
    &__content {
        margin-bottom: .8em;
        &--greeting {
            color: $grassGreen;
            text-align: center;
            font-size: 50px;
            margin-bottom: .5em;
            font-family: 'Special Elite', monospace;
            @media all and (min-width: $tablet) {
                text-align: left;    
            }
        }
    }
    &__subtitle {
        margin-top: 1em;;
        font-family: $fontTitle;
        font-size: 30px;
        padding-bottom: .8em;
        color: $forestGreen;
        &::after {
            content: '';
            display: inline-block;
            width: 40px;
            height: 40px;
            background-image: url('../../img/skills.png');
            background-repeat: no-repeat;
            position: absolute;
            margin-left: .35em;
            margin-top: .1em;
        }
    }
}

.wrapper{
    &__description {
        display: flex;
        flex-direction: column;
        @media all and (min-width: $tablet) {
            flex-direction: row;
            align-items: flex-start;
        }
    }
    &__image {
        align-self: center;
        margin-bottom: 1em;
        @media all and (min-width: $tablet) {
            margin-bottom: 0;
            margin-right: 1.4em;
            align-self: flex-start;
        }
    }
}

.skills {
    &-technical,
    &-scrum,
    &-software {
        display: flex;
        flex-flow: row wrap;
    }
    &__item {
        background-color: lightgrey;
        margin: 5px 10px 5px 0;
        padding: 10px 16px;
        text-transform: uppercase;
        &.tech {
            background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
        }
        &.scrum {
            background-image: linear-gradient(120deg, #f3fed9 0%, #e5f9e8 100%);
        }
        &.software {
            background-image: linear-gradient(120deg, #f9ffec 0%, #f5fdf6 100%);
        }
    }
}
