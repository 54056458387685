.section-contact {
    text-align: center;
}

.contact {
    &__list {
        display: flex;
        justify-content: center;
        margin-top: 1.2em;
        flex-flow: row wrap;
    }
}
