@import './scss/variables';

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: $fontPrincipal;
  font-size: 20px;
  color: $blackRock;
  line-height: 1.4;
  background:
		linear-gradient(90deg, #fff (26px - 2px), transparent 1%) center,
		linear-gradient(#fff (26px - 2px), transparent 1%) center,
		rgb(233, 233, 233);
  background-size: 26px 26px;
}
