@import '../../scss/variables';

@media all and (min-width: $desktop) {
    .column-center {
        max-width: 1000px;
        margin: 0 auto;
    }
}

.main-content {
    padding: 20px;
    @media all and (min-width: $smallMobile) {
        padding: 26px;
    }
    @media all and (min-width: $desktop) {
        padding: 0;
    }
}
