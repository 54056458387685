@import '../../scss/variables';

.wrapper-nav {
    background-color: $grassGreen;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 50px;
    @media all and (min-width: $tablet) {
        box-shadow: 0 3px 5px rgba($darkGreen, .7);
        height: 70px;
    }
}

.hamburger-menu {
    width: 50px;
    height: 50px;
    padding: 5px;
    background-size: 32px 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../img/menu.png');
    cursor: pointer;
    transform: rotate(0);
    transition: all .15s ease-in-out;
    @media all and (min-width: $tablet) {
        display: none;
        position: absolute;
        top: -500px;
        transition: none;
    }
}

.is-active {
    background-image: url('../../img/close.png');
    background-size: 28px 28px;
    transform: rotate(90deg);
    transition: all .15s ease-in-out;
    @media all and (min-width: $tablet) {
        transition: none;
    }
}

.main-nav {
    background-color: $grassGreen;
    font-family: $fontTitle;
    font-size: 0;
    height: 0;
    @media all and (min-width: $tablet) {
        height: auto;
        font-size: 20px;
    }
    
    &.active-nav {
        height: auto;
        font-size: 20px;
        padding: 20px 20px 40px 20px;
        transition: all .3s cubic-bezier(.03, 2.00, .83, -0.07);
        transition: all .2s linear;
        @media all and (min-width: $tablet) {
            transition: none;
            padding: 0;
        }
    }
    
    &__list {
        @media all and (min-width: $tablet) {
            height: 70px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
        @media all and (min-width: $desktop) {
            justify-content: space-between;

        }
    }
    &__item {
        cursor: pointer;
        padding-bottom: 15px;
        @media all and (min-width: $tablet) {
            padding-bottom: 0;
            & > a {
                padding: 16px;
            }
        }
        &:hover {
            color: $snowWhite;
            transition: all .1s;
        }
    }
    &__item:last-child {
        padding-bottom: 0;
        @media all and (min-width: $tablet) {
            padding-bottom: 0;
        }
    }
}