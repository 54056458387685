// FONTS
$fontPrincipal: 'Muli', sans-serif;
$fontTitle: 'Playfair Display', serif;

// COLORS
$blackRock: #111;
$snowWhite: #fff;
$swampGreen: #0A2E36;
$springGreen: #27FB6B;
$grassGreen: #14CC60;
$forestGreen: #119822;
$darkGreen: #0A3200;

// QUERIES
$smallMobile: 375px;
$tablet: 768px;
$desktop: 1160px;