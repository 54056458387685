.contact {
    &__item {
        margin-right: 30px;
        margin-bottom: 30px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__image {
        display: block;
        width: 50px;
        height: 50px;
        background-size: contain;
    }
}

.email {
    background-image: url('../../img/contact/email.png');
}

.github {
    background-image: url('../../img/contact/github.png');
}

.linkedin {
    background-image: url('../../img/contact/linkedin.png');
}

.twitter {
    background-image: url('../../img/contact/twitter.png');
}
