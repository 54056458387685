@import '../../scss/variables';

.project {
    background-color: $snowWhite;
    box-shadow: 2px 2px 10px rgba($blackRock, 0.2);
    &__image {
        display: block;
        height: 220px;
        width: 100%;
        object-fit: cover;
        object-position: top left;
    }
    &__name {
        border-left: 2px solid $darkGreen;
        color: $darkGreen;
        padding-left: 6px; 
        margin-bottom: 22px;
        font-size: 24px;
        line-height: 1.3;
    }
    &__text {
        margin-bottom: .8em;
    }
    &__url {
        color: $grassGreen;
        font-weight: 600;
        padding: 1em;
        display: inline-block;
        text-transform: uppercase;
        border: 2px solid $grassGreen;
        transition: all .3s ease;
        &:hover {
            background-color: $grassGreen;
            color: $snowWhite;
            transition: all .3s ease;
        }
    }
}

.wrapper {
    &__info {
        padding: 15px;
    }
    &__url {
        display: flex;
        justify-content: space-between;
    }
}